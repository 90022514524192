export default{
    sampleDatafields:['Type', 'Friendly Name', 'URL/IP', 'Port', 'Keyword Value', 'Keyword Type', 'interval', 'dayCount', 'grace_interval','devicetype'],
    sampleData:[
        {'Type':'HTTP', 'Friendly Name':'HTTP_EXAMPLE', 'URL/IP':'https://pingmonk.com/', 'Port':'', 'Keyword Value':'', 'Keyword Type':'if not exists', 'interval':'1', 'dayCount':'', 'grace_interval':'0','devicetype':'Desktop'},
        {'Type':'KEYWORD', 'Friendly Name':'KEYWORD_EXIST_EXAMPLE', 'URL/IP':'https://pingmonk.com/', 'Port':'', 'Keyword Value':'monitor', 'Keyword Type':'if not exists', 'interval':'1', 'dayCount':'', 'grace_interval':'0','devicetype':'Desktop'},
        {'Type':'KEYWORD', 'Friendly Name':'KEYWORD_NOTEXIST_EXAMPLE', 'URL/IP':'https://pingmonk.com/', 'Port':'', 'Keyword Value':'monitor', 'Keyword Type':'if not exists', 'interval':'1', 'dayCount':'', 'grace_interval':'0','devicetype':'Desktop'},
        {'Type':'PING', 'Friendly Name':'PING_EXAMPLE', 'URL/IP':'pingmonk.com', 'Port':'', 'Keyword Value':'', 'Keyword Type':'if not exists', 'interval':'1', 'dayCount':'', 'grace_interval':'0','devicetype':'Desktop'},
        {'Type':'PORT', 'Friendly Name':'PORT_EXAMPLE', 'URL/IP':'195.154.119.99', 'Port':'443', 'Keyword Value':'', 'Keyword Type':'if not exists', 'interval':'1', 'dayCount':'', 'grace_interval':'0','devicetype':'Desktop'},
        {'Type':'HEARTBEAT', 'Friendly Name':'HEARTBEAT_EXAMPLE', 'URL/IP':'', 'Port':'', 'Keyword Value':'', 'Keyword Type':'if not exists', 'interval':'1440', 'dayCount':'2', 'grace_interval':'30','devicetype':'Desktop'},
        {'Type':'PAGE_SPEED', 'Friendly Name':'PAGESPEED_EXAMPLE', 'URL/IP':'https://pingmonk.com/', 'Port':'', 'Keyword Value':'', 'Keyword Type':'if not exists', 'interval':'60', 'dayCount':'', 'grace_interval':'0','devicetype':'Desktop'},
        {'Type':'PAGE_SCREENSHOT', 'Friendly Name':'PAGE_SCREENSHOT_EXAMPLE', 'URL/IP':'https://pingmonk.com/', 'Port':'', 'Keyword Value':'', 'Keyword Type':'if not exists', 'interval':'180', 'dayCount':'', 'grace_interval':'0','devicetype':'Tablet'},
    ]
}