<template>
    <div>
      <div class="container-fluid">
        <!-- <div class="row">
        <div class="col f-24">
          <span>Monitor : </span><span class="text-info">{{ monitor_data.name }}</span>
        </div>
        </div> -->
              <div class="row">
                  <div class="col call-chat-body cust_h_w">
                    <!-- <px-card> -->
                    <b-row class="p-2">
                      <b-col class="col-md-3 col-12 text-center text-md-left">
                        <b-button v-if="(chart_tab)" class="font-weight-bold" variant="info">Chart</b-button>
                        <b-button v-else class="font-weight-bold" @click="(chart_tab=true)" variant="light">Chart</b-button>
                        <b-button v-if="(chart_tab == false )" class="font-weight-bold" variant="info" @click="(chart_tab=false)">Logs</b-button>
                        <b-button v-else class="font-weight-bold" variant="light" @click="(chart_tab=false)">Logs</b-button>
                      </b-col>
                      <b-col class="col-md-3 col-12 text-center text-md-left mt-2 mt-md-0">
                        <date-range-picker
                                ref="picker"
                                opens="center"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                                :singleDatePicker="false"
                                :timePicker="true"
                                :timePicker24Hour="true"
                                :showWeekNumbers="false"
                                :showDropdowns="true"
                                :autoApply="false"
                                v-model="dateRange"
                                @update="ChangedDate()"
                                :linkedCalendars="false"
                                :ranges="false"
                        >
                        <template v-slot:input="picker" style="min-width: 350px;">
                          <span style="font-size: 80%;"> {{ changeDateFormate(dateRange.startDate) }} - {{ changeDateFormate(dateRange.endDate) }}</span> 
                        </template>
                        </date-range-picker> 
                      </b-col>
                      <b-col class="col-md-2 col-12 text-center text-md-left">
                        <b-button variant="primary" @click="Filterlogs()">Filter</b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                    </b-row>
                    
                    <b-row class="mt-2" v-if="chart_tab">
                      <div v-if="chart_loading" class="col-md-12 mt-5">
                        <h6 class="sub-title mb-0 text-center">Loading...</h6>
                        <div class="loader-box">
                          <div class="loader-30"></div>
                        </div>
                    </div>
                      <div class="col-xl-12 col-md-12 col-sm-12">
                        <px-card title="">
                          <div slot="headerCustom">
                            <span>
                              <h5 class="font-weight-bold">Response Time last 24 hours</h5>
                            </span>
                            <span class="">
                              Shows the "instant" that the monitor started returning a
                              response in ms.
                            </span>
                            </div>
                              <div slot="headerAction" ></div>
                              <div slot="with-padding" >
                                <chartist
                                    class="ct-4 flot-chart-container"
                                    ratio="ct-major-second"
                                    type="Line"
                                    :data="chartData.data"
                                    :options="chartData.options">
                            </chartist>
                              </div>
                          </px-card>
                      </div>
                    </b-row>
                    <b-row class="mt-3 p-2" v-else>
                      <b-col class="col-md-7 col-12">
                        <monitor-logs :monitor_id="monitor_id" :dateRanges='changeDateRange' :update_filter="update_filter"></monitor-logs>
                      </b-col>
                      <b-col class="col-md-5 col-12">
                        <event-vue :monitor_id="monitor_id" :monitor_data="monitor_data" :dateRanges='changeDateRange' :update_filter="update_filter"></event-vue>
                      </b-col>
                    </b-row>
                <!-- </px-card>      -->
                  </div>
              </div>
          </div>
          <div slot="with-padding" v-if="monitor_data.type== 6 || monitor_data.type== 7">
          <div class="row mt-2">
            <div class="col-md-12">
              <!-- <div class="row">
                <div class="col-md-6 col-12">
                  <incedent-vue :monitor_id="monitor_id" :monitor_data="all_monitors" :dateRanges='changeDateRange' :update_filter="update_filter"></incedent-vue>
                </div>
                <div class="col-md-12 col-12">
                  <event-vue :monitor_id="monitor_id" :monitor_data="monitor_data" :dateRanges='changeDateRange' :update_filter="update_filter"></event-vue>
                </div>
              </div> -->
              <div class="row">
                <div class="col-md-12">
                  <pageload-data :monitor_id="monitor_id" :monitor_data="monitor_data" :dateRanges='changeDateRange' :update_filter="update_filter"></pageload-data>
                </div>
              </div>
            </div>
          </div></div>
    </div>
  </template>
  
  
  
  
  <script>
  import IncedentVue from "./Incedent.vue";
  import EventVue from "./Events.vue";
  import MonitorLogs from "./MonitorLogs.vue";
  import PageloadData from "./PageLoadData.vue";
  import MonitorsController from "../../services/monitorController";
  import DashboardController from "../../services/dashboardController";
  import DateRangePicker from 'vue2-daterange-picker';
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
  import moment from 'moment';
  export default {
    name: "ReportFilter",
    components: { IncedentVue, EventVue, PageloadData,MonitorLogs, DateRangePicker },
    props:{monitor_id:String,monitor_data:Object},
    data() {
      return {
        logs_data:[],
        limit:50,
        dateRange:{startDate: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)),endDate: new Date(new Date().getTime() + (1 * 60 * 60 * 1000))},
        changeDateRange:null,
        chart_tab:false,
        log_tab:true,
        filter_monitor:'',
        monitor_name:'',
        chart_loading: false,
        show_chart: false,
        all_monitors:[],
        filter: {
          monitor: null,
          sdate: "2022-11-11T01:00:00",
          edate: "2022-11-11T23:45:00",
        },
        timelineData: [],
        chartData: {
          data: {
            labels: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              20, 21, 22, 23, 24,
            ],
            series: [
              [
                20, 30, 10, 4, 25, 50, 60, 80, 26, 35, 34, 63.75, 87, 43, 12, 43,
                54, 76, 54, 32, 33, 43, 54, 65,
              ],
            ],
          },
          options: {
            low: 0,
            showArea: true,
          },
        },
        monitor_type: {1:'HTTP(s)',2:'Keyword',3:'Ping',4:'Port',5:'Heartbeat',6:'Page Speed',7:'Page Screenshot'},
        update_filter:0
      };
    },
    mounted() {
      this.log_tab=true
      this.getMonitors();
      this.filterData(this.monitor_id);
    },
    methods: {
      Filterlogs(){
          this.changeDateRange = this.dateRange
          this.update_filter = Math.random() * 100;
          this.filterData(this.monitor_id, this.dateRange)
      },
      async getMonitors() {
      let response = await MonitorsController.getMonitors();
      if (response.result) {
        response.data.map((t) => {
          this.all_monitors.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
        });
      }
    },
      changeDateFormate(d){
          return moment(d).format('D/M/YYYY, HH:mm')
      },
      ChangedDate(){
        // let sdate = new Date(
        //   this.dateRange.startDate.getTime() - this.dateRange.startDate.getTimezoneOffset() * 60000
        // ).toISOString();
  
        // let edate = new Date(
        //   this.dateRange.endDate.getTime() - this.dateRange.endDate.getTimezoneOffset() * 60000
        // ).toISOString();
        // this.dateRange.startDate = sdate
        // this.dateRange.endDate = edate
        // this.filterData(this.monitor_id, this.dateRange)
      },
      async filterData(value,dateRange) {
        this.monitor_id = value;
        this.show_chart = false;
        this.chart_loading = false;
        if (this.monitor_id) {
          this.chart_loading = true;
          this.chartData.data.labels = [];
          this.chartData.data.series = [];
          let payload = {
            dim: {
              mid: [this.monitor_id]
            },
            limit:this.limit
          };
          if(dateRange){
            payload.sdate = dateRange.startDate
            payload.edate = dateRange.endDate
          }
          let response = await DashboardController.timelineData(payload);
          if (response.message == "success") {
            if(response.data.length>0){
            this.timelineData = response.data;
            this.timelineData.map((t) => {
              this.chartData.data.labels.push(t.hour);
            });
            this.chartData.data.series.push(
              this.timelineData.map((t) => {
                return t.rtime;
              })
            );
            this.show_chart = true;
          }
          else{
            // this.$toasted.show('chart data not available.', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
          }
            this.chart_loading = false;
          }
        }
      }
    },
  };
  </script>
  
  
  <style>
  .ct-series-a .ct-slice-pie,
  .ct-series-a .ct-slice-donut-solid,
  .ct-series-a .ct-area {
    fill: #989219 !important;
  }
  .ct-series-a .ct-point,
  .ct-series-a .ct-line,
  .ct-series-a .ct-bar,
  .ct-series-a .ct-slice-donut {
    stroke: #989219 !important;
  }
  .ct-area {
    fill-opacity: 0.6 !important;
  }
  .custom-badge{
    background:#999;color:white;font-size: 9px !important;
  }
  .monitor_filter{
        position: sticky;
      top: 0;
  }
  .daterangepicker th.prev, .daterangepicker th.next, .daterangepicker td.prev, .daterangepicker td.next {
    background: none !important;
  }
  .cust_h_w{
    min-height:60vh;max-height:75vh;overflow:scroll;
  }
  @media (max-width: 767px) {
    .cust_h_w {
      min-height:auto;max-height:auto;overflow:scroll;
    }
  }
  </style>