<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <!-- <px-card> -->
            <div slot="">
              <b-row class="">
                <b-col cols="5">
                  <h4 class="f-w-900"> Logs </h4>
                </b-col>
                <!-- <b-col class="col-md-4 col-12">
                  <b-input-group class="">
                    <b-form-input
                      v-model="filter"
                      placeholder="Type to Search"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-col> -->
              <b-col cols="7" class="text-center text-md-right">
                <label>Per Page:</label>
                  <b-form-select
                    class="col-xl-4 col-md-2 col-6 col-sm-4 ml-2"
                    v-model="perPage"
                    :options="pageOptions"
                    @change="getLogs()"
                  ></b-form-select>
              </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div>
              <div v-else class="table-responsive datatable-vue text-center">
                <b-table
                  show-empty
                  stacked="md"
                  :items="logs_data"
                  :filter="filter"
                  @filtered="onFiltered"
                  :per-page="perPage"
                  :fields="tablefields"
                >
                  <template #cell(ename)="data">
                    <span
                      v-if="data.item.ename == 'start'"
                      class="badge badge-flat badge-primary"
                    >
                    Started</span
                    >
                    <span
                      v-if="data.item.ename == 'pause'"
                      class="badge badge-flat badge-dark text-white"
                    >
                    Paused </span
                    >
                    <span
                      v-if="data.item.ename == 'down'"
                      class="badge badge-flat badge-danger"
                    >
                    Down </span
                    >
                    <span
                      v-if="data.item.ename == 'up'"
                      class="badge badge-flat badge-success"
                    >
                    UP </span
                    >
                    <span
                      v-if="data.item.ename == 'stale'"
                      class="badge badge-flat badge-warning text-white"
                    >
                    STALE </span
                    >
                  </template>
                  <template #cell(reason)="data">
                    <span class="res_text">{{ data.item.reason }}</span>
                    <!-- <textarea class="text-center long_text" :value="data.item.reason" name="" id="" cols="10" rows="1" style="" disabled></textarea> -->
                  </template>
                  <template #cell(timestamp)="data">
                    <!-- <span class="text-info">{{changeDateFormate(data.item.timestamp)}}</span> -->
                    {{ new Date(data.item.timestamp+'Z').toLocaleString(undefined,{year: 'numeric', month: 'numeric',day: 'numeric',hour: 'numeric',minute: 'numeric', hour12: false})}}
                  </template>
                  <template #cell(rtime)="data">
                  <span class="">{{convertMsToHM(data.item.rtime)}}</span>
                </template>
                </b-table>

                <b-row class="col-md-12">
                  <b-col class="p-2 mt-2 text-left">
                    <div class="pagination">
                      <a v-show="currentPage != 1" @click="getLogs('prev', dateRanges)">&laquo; Back</a>
                      <a class="active">{{currentPage}}</a>
                      <a v-show="logs_data.length >= 1" @click="getLogs('next', dateRanges)">Next &raquo;</a>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          <!-- </px-card> -->
        </div>
      </div>
    </div>
</template>
  
  
<script>
import moment from 'moment'
import DashboardController from "../../services/dashboardController";
import HelperFunction from "../../services/helperFunction"
export default {
  name: "Event",
  props:{monitor_id:String,dateRanges:Object,update_filter:Number},
  data() {
    return {
      logs_data: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      loading: false,
      tablefields: [
      { key: "ename", label: "Event", sortable: false },
      { key: "reason", label: "Reason", sortable: false },
      { key: "rtime", label: "Response Time", sortable: false },
      { key: "timestamp", label: "Date", sortable: false },
      ],
    };
  },
  mounted() {
    this.getLogs('', this.dateRanges);
  },
  watch:{
    monitor_id(){
      this.currentPage = 1
      this.getLogs()
    },
    update_filter(){
        this.getLogs('', this.dateRanges)
    }
  },
  methods: {
    changeDateFormate(d){
        return moment(d).format('D/M/YYYY, HH:mm A')
    },
    async getLogs(txt, dateRange = false) {
        dateRange = dateRange ? dateRange : this.dateRanges
        if(txt == 'next'){this.currentPage ++}
        if(txt == 'prev'){
          if(this.currentPage != 1){
            this.currentPage --
          }
        }
        if(this.monitor_id && this.monitor_id != null){
        let payload = {
          dim: {
            mid: [this.monitor_id]
          },
          limit: this.perPage,
          page: this.currentPage
        };
        if(dateRange){
            payload.sdate = dateRange.startDate
            payload.edate = dateRange.endDate
        }
        
      this.loading = true;
      let response = await DashboardController.getlogsdata(payload);
      if (response.result) {
        this.logs_data = response.data;
      }
    }
      this.loading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    convertMsToHM(millis) {
      return HelperFunction.convertMsToHM(millis)
    }
  },
};
</script>

<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd;cursor: pointer;}
</style>