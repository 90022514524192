<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <form action="" @submit.prevent="onSubmit()">
          <px-card>
            <b-row>
              <b-col>
                <h6 class="text-primary">Action Details</h6>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col md="3" class="font-weight-bold">
                I want to *
              </b-col>
              <b-col md="6">
                <div class="form-group">
                  <b-form-select
                    name="type"
                    size="sm"
                    v-model="action_type"
                    :options="action_opt"
                    required
                  ></b-form-select>
                </div>
              </b-col>
            </b-row>
            <hr>
            <div v-if="action_type">
            <b-row>
              <b-col md="3" class="font-weight-bold">
                Applies to *
              </b-col>
              <b-col md="6">
                <div class="form-group">
                  <b-form-select
                    name="type"
                    size="sm"
                    v-model="appliesto"
                    :options="appliesto_opt"
                    required
                  ></b-form-select>
                </div>
              </b-col>
            </b-row>
            <hr>
          </div>
            <div v-if="appliesto==2">
            <b-row>
                <b-col  class="font-weight-bold">
                Select Monitors
                </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <div class="form-group">
                  <multiselect variant="success"
                  :close-on-select="false"
                   v-model="selected_monitors" tag-placeholder="Select Monitors" placeholder="Search or add a Monitors" 
                  label="text" track-by="value" :options="monitor_opt" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                </div>
              </b-col>
            </b-row>
            <hr>
          </div>
          <div  v-if="action_type==8">
          <b-row>
              <b-col md="3" class="font-weight-bold">
                Alert Frequency
              </b-col>
              <b-col class="col-md-6 col-12">
                  <b-form-select name="type" size="sm" v-model="input.downAlertFreqType" :options="alert_freq_opt" required>
                      <b-form-select-option value=1 :disabled="not_premium" title="paid">{{not_premium?'once per hour (pro plan)':'once per hour'}}</b-form-select-option>
                      <b-form-select-option value=2 :disabled="not_premium" title="paid">{{not_premium?'once per day (pro plan)':'once per day'}}</b-form-select-option>
                      <b-form-select-option value=3 :disabled="not_premium" title="paid">{{not_premium?'once per week (pro plan)':'once per week'}}</b-form-select-option>
                      <b-form-select-option value=4 :disabled="not_premium" title="paid">{{not_premium?'once per month (pro plan)':'once per month'}}</b-form-select-option>
                  </b-form-select>
              </b-col>
            </b-row><hr>
          </div>
            <div v-if="action_type == 4">
              <b-row>
                <b-col  md="3" class="font-weight-bold">
                  Monitoring Interval *
                </b-col>
                <b-col md="4">
                  <div class="slidecontainer">
                    <span class="font-weight-bold text-info" v-if="input.interval <=60">every <span class="text-danger">{{input.interval}}</span> minutes</span>
                    <span class="font-weight-bold text-info" v-if="input.interval >=60">every <span class="text-danger">{{Math.round(input.interval /60,2)}}</span> hours</span>
                    <input type="range" :min="plan_details.minIntervalLimit" max="1440" v-model="input.interval" class="slider" id="myRange">
                  </div>
                </b-col>
              </b-row><hr>
            </div>
            <div v-if="action_type == 5">
              <b-row>
                <b-col  md="3" class="font-weight-bold">
                  Monitoring Timeout *
                </b-col>
                <b-col md="4">
                  <div class="slidecontainer">
                    <span class="font-weight-bold text-info">in <span class="text-danger">{{input.timeout}} </span>sec</span>
                    <input type="range" min="1" max="60" v-model="input.timeout" class="slider" id="myRange">
                  </div>
                </b-col>
              </b-row><hr>
            </div>
            <div v-if="action_type == 6">
                <b-row>
                  <b-col class="font-weight-bold">
                    Select Alert Contacts To Notify
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                  <multiselect :close-on-select="false"
                       size="sm" v-model="alertContacts" tag-placeholder="Add this as new tag" placeholder="Select alerts" 
                      label="text" track-by="value" :options="alerts" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                   </b-col>
                </b-row><hr>
            </div>
            <div v-if="action_type == 7">
              <b-row class="">
                  <b-col md="3" class="font-weight-bold">
                      Monitor SSL errors
                  </b-col>
                  <b-col md="1">
                      <input class="checkbox_animated" name="ssl_error" type="checkbox" v-model="input.ssl_error">
                  </b-col>
              </b-row><hr>
              <b-row class="" >
                  <b-col md="3" class="font-weight-bold">
                      Enable SSL expiry reminders
                  </b-col>
                  <b-col md="1">
                      <input class="checkbox_animated" name="ssl_expiry_reminder" type="checkbox" v-model="input.ssl_expiry_reminder">
                  </b-col>
                  <b-col md="">
                      <span class="text-info"><small>Monitor URL needs to start with "https" for the SSL monitoring to work.</small></span>
                  </b-col>
                </b-row><hr>
            </div>
            <div v-if="action_type">
            <b-row>
              <b-col>
                <h6 class="text-primary">Action Approval</h6>
              </b-col>
            </b-row><hr>
            <b-row>
              <b-col>
              Please write&nbsp;"<span class="text-info"><b>{{action_txt[action_type]}}</b></span>"&nbsp;to  the field below to approve the action.
            </b-col>
            </b-row><hr>
            <b-row>
              <b-col md="3" class="font-weight-bold">
                Approval Text *
              </b-col>
              <b-col>
                <b-input size="sm" type="text" v-model="approval_text"></b-input>
              </b-col>
            </b-row>
            <hr>
          </div>
          <span v-if="isloading">
            <div class="loader-box">
              <div class="loader-30"></div>
            </div>
          </span>
            <b-row class="mt-2">
              <b-col class="text-center">
                <b-button type="submit" variant="primary" :disabled="isloading">Complete Action</b-button>
              </b-col>
            </b-row>
          </px-card>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
import Multiselect from 'vue-multiselect'
import MonitorsController from "../../services/monitorController";
export default {
  name: "BulkAction",
  components:{Multiselect},
  data() {
    return {
      isloading:false,
      alerts:[],
      alertContacts:[],
      plan_details:{minIntervalLimit:5},
      approval_text:'',
      input:{monitors:[],changePayload:{},interval:5,timeout:5,ssl_expiry_reminder:false,ssl_error:false,downAlertFreqType:null},
      action_type:null,
      appliesto:null,
      action_opt: [
        { value: null, text: "Please Select" },
        { value: "1", text: "Pause Monitors" },
        { value: "2", text: "Start Monitors" },
        { value: "3", text: "Delete Monitors" },
        { value: "4", text: "Change Intervals Of Monitors" },
        { value: "5", text: "Change Timeouts Of Monitors" },
        { value: "6", text: "Change Alert Contacts Of Monitors" },
        { value: "7", text: "Change SSL Settings Of Monitors" },
        { value: "8", text: "Change Alert Frequency Of Monitors" },
      ],
      appliesto_opt:[
        { value: null, text: "Please Select" },
        { value: "1", text: "For all the monitors" },
        { value: "2", text: "For selected monitors" },
      ],
      action_txt:{1:'PAUSE MONITORS',2:'START MONITORS',3:'DELETE MONITORS',4:'CHANGE INTERVAL',
                 5:'CHANGE TIMEOUT', 6:'CHANGE ALERT CONTACTS',7:'CHANGE SSL', 8:'CHANGE ALERT FREQUENCY'},
      monitor_opt:[],
      all_monitors:[],
      selected_monitors:null,
      not_premium:false,
      alert_freq_opt: [
              { value: null, text:'select frequency' },
              { value: 0, text:'once' },
      ],
    };
  },
  mounted(){
    this.plan_details = this.$root.$data.plan_details
    if(this.plan_details.plan_type == '1'){
            this.not_premium = true
    }
    this.getMonitors(); 
    this.getAlerts();
    },
    methods:{
      async onSubmit(){
        if(this.validate()){
        this.isloading = true
        let monitors = []
        if(this.selected_monitors != null){
          this.selected_monitors.map((t)=>{ monitors.push(t.value)})
        }
        var alertContacts = []
        if(this.alertContacts != null){
            this.alertContacts.map((l)=>{
                alertContacts.push(l.value)
            })
        }
        let payload={}
        switch(this.action_type){
          case '1': payload={'monitors':monitors,'changePayload':{'status': false}}
            break;
          case '2':payload={'monitors':monitors,'changePayload':{'status': true}}
            break;
          case '3':payload={'monitors':monitors,'changePayload':{'isDelete': true}}
            break;
          case '4':payload={'monitors':monitors,'changePayload':{'interval': this.input.interval}}
            break;
          case '5':payload={'monitors':monitors,'changePayload':{'timeout': this.input.timeout}}
            break;
          case '6':payload={'monitors':monitors,'changePayload':{'alertContacts': alertContacts}}
            break;
          case '7':payload={'monitors':monitors,'changePayload':{'ssl_expiry_reminder':this.input.ssl_expiry_reminder,ssl_error:this.input.ssl_error}}
            break;
          case '8':payload={'monitors':monitors,'changePayload':{'downAlertFreqType':this.input.downAlertFreqType}}
            break;
        }
        let response = await MonitorsController.bulkAction(payload);
        if(response.result){
          this.approval_text=''
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
          this.$emit('bulkmodal')
        }
        else{
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
        }
        this.isloading = false
      }
      },
      validate(){
        if(this.appliesto == 2 && this.selected_monitors == null){
          this.$toasted.show('Please select monitor!', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
          return false;
        }
        else if(this.approval_text === this.action_txt[this.action_type]){
          return true;
        }
        else{
          this.$toasted.show('Please enter correct approval text!', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
          return false;
        }
      },
      async getAlerts(){
            let response= await MonitorsController.getAlerts()
            if(response.data){
            response.data.map((t)=>{
                if(t.status){
                this.alerts.push({ value: t._id, text:t.name })
                }
            })
            }
         },
      async getMonitors() {
      let response = await MonitorsController.getMonitors();
      if (response.result) {
          response.data.map((t) => {
          this.monitor_opt.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
          this.all_monitors.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
          });
      }
      },
      addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
      },
    }
};
</script>


<style scoped>
hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    height: 25px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    width: 100%;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */ 
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 15px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #1f9022; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #1f9022; /* Green background */
    cursor: pointer; /* Cursor on hover */
}
</style>