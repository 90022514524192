<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <px-card>
            <template slot="headerCustom">
            <b-row>
              <b-col cols="8">
              <h4 class="f-w-900"> Page Speed Data </h4>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-form-group
                    label-cols="6"
                    label="Per page"
                    class="mb-0"
                  >
                  <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                  ></b-form-select>
                  </b-form-group>
              </b-col>
              </b-row>  
            </template>
            <div slot="headerAction"></div>
            <div slot="" class="pb-3 pr-3 pl-3">
              <!-- <b-row class=""> -->
                <!-- <b-col class="col-md-9 col-12">
                  <b-input-group class="col-md-6">
                    <b-form-input
                      v-model="filter"
                      placeholder="Type to Search"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-col> -->

                <!-- <b-col class="text-right">
                  <b-form-group
                    label-cols="11"
                    label="Per page"
                    class="mb-0"
                  >
                    <b-form-select
                      class="col-md-12"
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col> -->
              <!-- </b-row> -->
              <div class="col-md-12" v-if="loading">
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div>
              <div v-else class="table-responsive datatable-vue text-center">
                <b-table
                  show-empty
                  stacked="md"
                  :items="pageload_data"
                  :filter="filter"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                  :per-page="perPage"
                  :fields="tablefields"
                >
                  <template #table-caption>Page Reload data.</template>
                  <template #cell(mName)="data">
                     <!-- {{ monitor_list[data.item.mid] }} -->
                    {{ monitor_data.name }}
                  </template>
                  <template #cell(screenshot)="data">
                    <span class="text-primary" @click="ImageModal(data.item)"><feather type="image"></feather></span>
                  </template>
                  <template #cell(createdAt)="data">
                    <span class="">{{changeDateFormate(data.item.createdAt)}}</span>
                  </template>
                </b-table>
                
              </div>
              <b-col md="12" class="mt-3 p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </div>
            
          </px-card>
          <b-modal id="modal-image" size="xl" title="Page Screen Shot" class="theme-modal" v-model="image_modal" no-close-on-backdrop hide-footer>
            <b-row>
                <b-col class="text-center">
                  <div v-if="load_img">
                    <div class="loader-box">
                    <div class="loader-30"></div>
                  </div></div>
                  <div v-else>
                    <img :src="'data:image/png;base64,'+image_data" style="max-width: 100%;max-height: 100%;" />
                  </div>
                </b-col>
            </b-row>
            <template #modal-footer>
            <b-button @click="(image_modal = false)" color="danger">Close</b-button>
            </template>
        </b-modal>
        </div>
      </div>
    </div>
</template>
  
  
<script>
import moment from 'moment'
import DashboardController from "../../services/dashboardController";
export default {
  name: "Event",
  props:{monitor_id:String,monitor_data:Object,dateRanges:Object,update_filter:Number},
  data() {
    return {
      load_img:false,
      image_data:[],
      image_modal:false,
      pageload_data: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50],
      loading: false,
      tablefields: [
        { key: "mName", label: "Name", sortable: true },
        { key: "RecalcStyleDuration", label: "Recalc Style Duration", sortable: false },
        { key: "ScriptDuration", label: "Script Duration", sortable: false },
        { key: "TaskDuration", label: "Task Duration", sortable: false },
        { key: "JSHeapTotalSize", label: "JSHeap TotalSize", sortable: false },
        { key: "createdAt", label: "Started At", sortable: false },
      ],
      // monitor_list:[]
    };
  },
  mounted() {
    if(this.monitor_data.type == 7){
      this.tablefields.push({ key: "screenshot", label: "Screenshot", sortable: false })
    }
    this.getPageloadData();
  },
  watch:{
    monitor_id(){
      this.currentPage = 1
      this.getPageloadData()
    },
    update_filter(){
        this.getPageloadData(this.dateRanges)
    }
  },
  methods: {
    async ImageModal(data){
      this.image_modal = true
      this.load_img = true
      let response = await DashboardController.getPageloadImage({"pageid": data._id});
      this.load_img=false
      this.image_data = this.arrayBufferToBase64(response.data.screenshot.data)
    },
    arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
    },
    changeDateFormate(d){
        return moment(d).format('D/M/YYYY, HH:mm A')
    },
    async getPageloadData(dateRange) {
      let payload = { "mid": this.monitor_id}
      if(dateRange){
            payload.sdate = dateRange.startDate
            payload.edate = dateRange.endDate
        }
      this.loading = true;
      // this.monitor_list = [];
      // for(var i=0; i<this.monitor_data.length; i++){
      //   this.monitor_list[this.monitor_data[i].value]=this.monitor_data[i].text
      // }
      let response = await DashboardController.getPageloadData(payload);
      if (response.result) {
        this.pageload_data = []
        this.pageload_data = response.data;
        this.totalRows = this.pageload_data.length;
      }
      this.loading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
};
</script>